@import "~bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.min.css";





body {
    background-color: white;
    padding-top:56px;
}

label {
    //display:block;
}

input {
    width:100%;
}

div.row + div.row{
    margin-top:20px;
}